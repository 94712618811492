.tariffH1 {
    font-weight: 700;
    margin-bottom: 1rem;
    font-family: var(--Alegreya);
}
.traiffContainer{
    background-image: url("../../../public/img/Trade/tradeSponserBanner.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.traiffBox{
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
    margin: 1rem;
}
.redHeading{
    margin-bottom: 0 !important;
    /* font-size: 1vw; */
    font-weight: 700;
    font-family: var(--Alegreya);
    color: #f05d3e;
    
}
.tradePresonName{
    /* font-size: 1vw; */
    font-weight: 700;
    font-family: var(--Alegreya);
}
.textoutline{
    border-radius: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.253);
    padding: 5px 20px;
    width: fit-content;
}
.color-green{
    color: #007a24;
}
.tariffTable tbody tr td{
    padding: 1rem;
    /* font-size: 1vw; */
    font-weight: 700;
    font-family: var(--Outfit);
    text-align: center;
}
@media (max-width: 990px) {
    .traiffContainer {
        min-height: 75vh;
    }
    .tradePresonName{
        margin: 0px;
    }
}
@media (max-width: 590px) {
    .traiffContainer {
        min-height: 145vh;
    }
    .tradePresonName{
        margin: 0px;
    }
    .tariffTable tbody tr td {
        font-size: 4vw;
    }
}