.srm_set {
    object-fit: contain;
    width: 100vw;
  }
  
  .inter_head {
    color: #147745;
    font-size: 3vw;
    text-align: center;
    font-weight: 700;
  }
  
  .inter_parra {
    font-size: 1.4vw;
    text-align: center;
  }
  
  .span_green {
    color: #147745;
  }
  
  .organized_head {
    font-size: 2vw;
    color: #147745;
    text-align: center;
  }
  
  .chief_head {
    font-size: 2vw;
    color: #147745;
    text-align: center;
  }
  
  .patron_head {
    color: black;
    font-size: 1.3vw;
    text-align: center;
    font-weight: 600;
  }
  
  .patron_parra {
    color: black;
    font-size: 1vw;
    text-align: center;
  }
@media only screen and (max-width: 990px) {
  .inter_head {
    font-size: 3.7vw;
  }

  .inter_parra {
    font-size: 2.5vw;
  }

  .organized_head {
    font-size: 4vw;
  }

  .chief_head {
    font-size: 3vw;
  }

  .patron_head {
    font-size: 2.8vw;
  }

  .patron_parra {
    font-size: 2vw;
  }

}
@media only screen and (max-width: 600px) {

  .inter_head {
    font-size: 4vw;
  }

  .inter_parra {
    font-size: 3vw;
  }

  .organized_head {
    font-size: 4.7vw;
  }

  .chief_head {
    font-size: 4vw;
  }

  .patron_head {
    font-size: 3.5vw;
  }

  .patron_parra {
    font-size: 3vw;
  }
}