.contact_background {
    overflow: hidden;
    background-image: url("../../assets/home.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .contact_head {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 4.16vw;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
  }
  .linkALL{
    text-decoration: none;
  }
  .contacts_para {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
    color: #ffffff;
    text-align: center;
  }
   
  .bangok_head {
    font-family: "Georgia";
    font-style: normal;
    font-weight: 700;
    font-size: 1.56vw;
    /* margin-right: 10%; */
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  .contact_logo {
    width: 10.65vw !important;
  }
  .organized {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 1.56vw;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0px;
  }
   
  .map {
    width: 43.8vw;
    height: 38.17vw;
  }
  .places_back {
    background: rgba(0, 0, 0, 0.68);
    padding: 1% 0px;
  }
  .places_head {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 2vw;
    color: #ffffff;
  }
  .contact_paras {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 0.9vw;
    color: #ffff;
  }
  .yellow_para {
    color: #ffce5c;
  }
  .btn_direction {
    display: flex;
    padding: 10px;
    width: 10.75vw;
    font-size: 1vw;
    justify-content: space-between;
    /* background: #1e3f94; */
    border: none;
    outline: none;
    color: white;
}
.btn_direction1 {
    display: flex
    ;
        padding: 10px;
        width: 10.75vw;
        font-size: 1vw;
        justify-content: center;
        background: #ffffff;
        border: none;
        outline: none;
        border-radius: 5px;
        color: #13997f;
    }
   
  @media only screen and (max-width: 990px) {
    .contact_head {
      font-size: 5vw;
      text-align: center;
    }
    .contacts_para {
      text-align: center !important;
      font-size: 3vw;
      width: 100%;
    }
    .world_contact_img {
      width: 50vw;
    }
    .bangok_head {
      font-size: 3vw;
      margin-right: 0px;
    }
    .organized {
      font-size: 2.5vw;
    }
    .contact_logo {
      width: 15vw !important;
    }
    .map {
      width: 60vw;
      height: 70vw;
      margin: 0 auto;
    }
    .places_head {
      font-size: 4vw;
      text-align: center;
    }
    .contact_paras {
      font-size: 2vw;
    }
    .btn_direction,
    .btn_direction1 {
      font-size: 2vw;
      width: 20vw;
    }
  }
   
  @media only screen and (max-width: 600px) {
    .contact_head {
      font-size: 7vw;
      text-align: center;
    }
    .contacts_para {
      text-align: center !important;
      font-size: 3.5vw;
      width: 100%;
    }
    .world_contact_img {
      width: 70vw;
    }
    .bangok_head {
      font-size: 3.5vw;
      margin-right: 0px;
    }
    .organized {
      font-size: 3vw;
    }
    .contact_logo {
      width: 20vw !important;
    }
    .map {
      width: 60vw;
      height: 70vw;
      margin: 0 auto;
    }
    .places_head {
      font-size: 6vw;
      text-align: center;
    }
    .contact_paras {
      font-size: 3.5vw;
    }
    .btn_direction,
    .btn_direction1 {
      font-size: 4vw;
      width: 40vw;
    }
  }