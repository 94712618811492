.venue {
  font-weight: bold;
  color: #34495e;
}
h1,
h2 {
  color: #2c3e50;
  margin-top: 20px;
}
.table thead th {
  background-color: #f8f9fa;
}
