footer {
    background-color: #e2efd4;
    padding: 30px;
  }
  
  .foot_logo {
    width: 15vw;
  }
  
  .footer_head {
    font-size: 1.5vw;
    color: #147745;
    text-align: center;
  }
  
  .link_all {
    color: inherit;
    text-decoration: none;
  }
  
  .link_all:hover {
    color: inherit;
    text-decoration: none;
  }
  
  .quick_parra {
    font-size: 1vw;
    text-align: center;
  }
  
  .map {
    width: 20vw;
    height: 10vw;
    border-radius: 12px;
  }
  
  .end_head {
    border-top: 2px solid #147745;
  }
  @media only screen and (max-width: 990px) {


    .foot_logo {
      width: 40vw;
    }
  
    .srm {
      width: 25vw;
    }
  
    .footer_head {
      font-size: 3vw;
    }
  
    .quick_parra {
      font-size: 2.5vw;
    }
  
    .text_end {
      font-size: 1.9vw;
    }
  
    .map {
      width: 60vw;
      height: 30vw;
    }
  
  }
  @media only screen and (max-width: 600px) {
  
  
    .foot_logo {
      width: 60vw;
    }
  
    .srm {
      width: 35vw;
    }
  
    .footer_head {
      font-size: 5vw;
    }
  
    .quick_parra {
      font-size: 4vw;
    }
  
    .text_end {
      font-size: 2.5vw;
    }
  
  }