.about_section {
    margin-top: 1rem;
    background: linear-gradient(
        258.88deg,
        rgba(188, 182, 182, 0.176) 4.15%,
        rgba(255, 255, 255, 0.22) 92.6%
      ),
      linear-gradient(118.87deg, #ffffff 32.23%, rgba(255, 255, 255, 0) 97.34%),
      linear-gradient(
        322.02deg,
        rgba(255, 255, 255, 0.2) 1.13%,
        rgba(255, 255, 255, 0) 65.29%
      );
  }
  
  .about_section2 {
    margin-top: 8%;
    background: linear-gradient(
        258.88deg,
        rgba(188, 182, 182, 0.176) 4.15%,
        rgba(255, 255, 255, 0.22) 92.6%
      ),
      linear-gradient(118.87deg, #ffffff 32.23%, rgba(255, 255, 255, 0) 97.34%),
      linear-gradient(
        322.02deg,
        rgba(255, 255, 255, 0.2) 1.13%,
        rgba(255, 255, 255, 0) 65.29%
      );
  }