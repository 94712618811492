@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.AbstractContent {
  margin: 5vh 0px;
}
.abstract_head {
  font-size: 2.3vw;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
}
.abstract_head1 {
  font-size: 1.5vw;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
}
.abstract_head2 {
  font-size: 1.5vw;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
  font-family: "Fira Sans", sans-serif;
  margin-top: 3vw;
}
.guidline_listItems {
  /* font-size: 1vw; */
  padding: 7px;

}
.abstract_title{
    padding: 0;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
}

.abstract_content{
    margin-bottom: 10px;
}

@media only screen and (max-width: 990px) {
    .abstract_head{
        font-size: 4vw;
    }
    .abstract_head1{
        font-size: 3vw;
    }
    .abstract_head2{
        font-size: 2.5vw;
    }
    .guidline_listItems {
        font-size: 1.6vw;
        padding: 7px;
      }
    }
    @media only screen and (max-width: 600px) {
        .abstract_head{
            font-size: 6vw;
        }
        .abstract_head1{
            font-size: 4.5vw;
        }
        .abstract_head2{
            font-size: 4.5vw;
        }
    .guidline_listItems {
        font-size: 3.2vw;
        padding: 7px;
      }
}