.inputcontainer {
  width: 100%;
}
#forminput{
    width: 40vw;
}
@media screen and (max-width:612px) {
    #forminput{
        width: 90vw;
    } 
}