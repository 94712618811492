.navbar {
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.active::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: #147745;
  bottom: 0;
  left: 0;
}

.logo {
  width: 10vw;
}
.nav-link {
  font-size: 0.9vw !important;
  font-weight: 600;
  color: black;
  text-align: center;
  position: relative;
  padding-right: 0.6rem !important;
}

.nav-link:hover {
  color: black;
}

.btn_dowload {
  background-color: #147745;
  color: white;
  border: none;
  outline: none;
  font-size: 1vw;
  padding: 10px 20px;
  border-radius: 5px;
}
.mobile_container {
  display: none;
  margin-bottom: 12vh;
}
@media only screen and (max-width: 990px) {
  .desktop_navbar {
    display: none;
  }
  
  .mobile_container {
    display: block;
  }

  .mobile_container .header_mobile {
    position: fixed !important;
    display: block;
    top: 0;
    left: 0;
    overflow-x: hidden !important;
    z-index: 999;
  }

  .content {
    padding: 40px 5% 20px;
    text-align: justify;
    max-height: 100%;
    color: #333;
    overflow-y: scroll;
  }

  .content img {
    width: 100%;
    position: relative;
    display: block;
    margin: 40px auto 30px;
  }

  /* End container/placeholder */

  /* Menu header_mobile */
  .header_mobile {
    background: #fff;
    overflow: hidden !important;
    height: 12vh;
    width: 100%;
    z-index: 1;
    position: fixed !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s ease-out, background 1s ease-out;
  }

  .header_mobile.menu-open {
    height: 100%;
    background: #fff;
    transition: all 0.45s ease-out, background 0.8s ease-out;
  }

  /* Menu List items */
  .mobile-menu {
    clear: both;
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100vh;
  }

  .header_mobile ul.menu {
    position: relative;
    margin-bottom: 30vw;
    padding: 0px 40px 0;
    list-style: none;
  }

  .header_mobile ul.menu li.menu-item a {
    display: block;
    position: relative;
    color: #000;
    text-align: right;
    text-decoration: none;
    font-size: 3vw;
    line-height: 2.8;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
  }

  .header_mobile ul.menu li.menu-item {
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }

  .header_mobile ul.menu li.menu-item:nth-child(1) {
    transition-delay: 0.35s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(2) {
    transition-delay: 0.3s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(3) {
    transition-delay: 0.25s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(4) {
    transition-delay: 0.2s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(5) {
    transition-delay: 0.15s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(6) {
    transition-delay: 0.1s;
  }

  .header_mobile ul.menu li.menu-item:nth-child(7) {
    transition-delay: 0.05s;
  }

  .header_mobile.menu-open ul.menu li.menu-item {
    opacity: 1;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(1) {
    transition-delay: 0.05s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(2) {
    transition-delay: 0.1s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(3) {
    transition-delay: 0.15s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(4) {
    transition-delay: 0.2s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(5) {
    transition-delay: 0.25s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(6) {
    transition-delay: 0.3s;
  }

  .header_mobile.menu-open ul.menu li.menu-item:nth-child(7) {
    transition-delay: 0.35s;
  }

  .hamburger {
    font-size: 6vw;
    color: #000;
  }
  /* Menu Icon */
  .icon-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  .icon-container #menuicon {
    width: 20px;
    height: 10px;
    position: relative;
    display: block;
    margin: -4px auto 0;
    top: 50%;
  }
  .closed {
    display: none;
  }
  #menuicon .bar {
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  #menuicon .bar.bar1 {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }

  #menuicon .bar.bar2 {
    -webkit-transform: translateY(6px) rotate(0deg);
    transform: translateY(6px) rotate(0deg);
  }

  #menuicon .bar.bar3 {
    -webkit-transform: translateY(12px) rotate(0deg);
    transform: translateY(12px) rotate(0deg);
  }

  .menu-open .closed {
    display: block;
    font-size: 6vw;
    color: black;
    float: right;
    color: #000;
  }

  .menu-open .icon-container .hamburger {
    display: none;
  }

  .menu-open .logo {
    display: none !important;
    transition: all 0.5s ease;
  }

  .menu-open .icon-container #menuicon .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.1s;
  }

  .menu-open .icon-container #menuicon .bar.bar1 {
    -webkit-transform: translateY(4px) rotate(45deg);
    transform: translateY(4px) rotate(45deg);
  }

  .menu-open .icon-container #menuicon .bar.bar2 {
    -webkit-transform: translateY(3px) rotate(-45deg);
    transform: translateY(3px) rotate(-45deg);
  }

  .mobile_pallete {
    height: 12vh;
  }

  .navbar {
    display: none;
  }

  .logo {
    width: 20vw;
    transition: all 0.5s ease;
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 45vw;
  }

  .hamburger {
    font-size: 9vw;
  }

  .closed {
    font-size: 8vw !important;
  }

  .header_mobile ul.menu li.menu-item a {
    font-size: 5vw;
  }
}
