#homepage-banner {
  height: calc(60vw - 60px);
}

.home_banner_head {
  font-size: 2.5vw;
  color: white;
  font-weight: bold;
}
.pdf_images {
  width: 100%;
  height: 40vw;
}

.HomeContaier{
  background-image: url("../../../public/img/home/HomeContent/backgroundImg.webp");
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.parent{
  border: 1px solid #147745;
}
.line_head_para{
  color: white;
  font-size: clamp(1rem , 1vw , 2vw);
  font-family: var(--Outfit);
}
.line_head_para::after{
  content: "";
  display: block;
  background-color: #ffffff;
  height: 2px;
  width: 5%;
}
.line_head_para2{
  color: rgb(0, 0, 0);
  font-size: clamp(1rem , 1vw , 2vw);
  font-family: var(--Outfit);
  font-weight: 600;
}
.line_head_para2::after{
  content: "";
  display: block;
  background-color: #c7b073;
  height: 3px;
  width: 7%;
}
.HomeContaierEligibilitytop{
  padding: 5% 0px;
}
.HomeContaierEligibilitybottom , .AwardsContainer{
  padding: 5% 0px;
  background-color: #f5f7fb;
}
.contestHead{
  font-family: var(--Alegreya);
  font-size: clamp(1rem , 1.2vw , 2vw);
  color: #565656;
}
.contestHeadspan{
  color: black;
}
.logoHead{
  font-family: var(--Outfit);
  font-size: clamp(1rem , 1.5vw , 2vw);
}
.collegeHead{
  font-family: var(--Outfit);
  color: #c7b073;
  font-family: var(--Alegreya);
}
.collegePara{
  font-family: var(--Inter);
  font-size: clamp(1rem , 1.1vw , 2vw);
}
.registerBtn{
  background-color: #fff;
  color: black;
  border-radius: 5px;
  border: none;
  padding: 10px 35px;
  font-size: clamp(1rem , 1.5vw , 2vw);
  font-family: var(--Alegreya);
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}
.registerPara{
  font-family: var(--Inter);
  font-size: clamp(1rem , .8vw , 2vw);
  color: #b8b8b8;
}
.registerBtn:hover{
  background-color: #147745;
  color: #fff;
}
.whiteBox{
  width: 55%;
  background-color: rgba(255, 255, 255, 0.808);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
.whiteBoxHead{
  font-family: var(--Outfit);
  font-size: clamp(1rem , 1.2vw , 2vw);
  color: #000000;
  font-weight: 700;
}
.startext{
  font-family: var(--Outfit);
  font-size: clamp(1rem , 1vw , 2vw);
  color: #bdbdbd;
  font-weight: 500;
}
/* .CaseReportContainer{
  position: relative;

} */
.CaseReportContainer{
  margin: 5% 0px;
  background-color: #f5f7fb;
}
.CaseReportContainerRight{
  margin: 5% 0px;
}
.caseReportContainerRightInnerBox{
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin: 5% 0px;
}
.CaseReportContainerLeft{
  position: sticky;
  bottom: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  background-image: url("../../../public/img/home/HomeContent/caseImg.webp");
  background-size: cover;
  background-position: 0 30%;
  background-repeat: no-repeat;
  height: 100vh;
}
.CaseReportContainerLeftInner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.caseUl{
  margin: 15px 0px;
  
}
.caseUl li{
  padding: 1% 0px;
  font-family: var(--Outfit);
  font-size: clamp(1rem , 1vw , 2vw);
  color: #565656;
}
.joinHeadTop{
  font-size: clamp(1rem , 1.5vw , 2vw);
  color: #666666;
  font-weight: 600;
}
.joinHeadBottom{
  font-size: clamp(1rem , 3vw , 2vw);
  font-family: var(--Alegreya);
  font-weight: 600;
}
.gridBox{
  display: grid;
  grid-template-columns: repeat( 3, 1fr);
}
.gridChild{
  border: .2px solid rgba(0, 0, 0, 0.301);
  padding: 20px;
  text-align: center;
}
.RequirementsContainer{
  padding: 3% 0px;
}
.greyBox{
  background-color:#f5f7f9;
  padding: 5%;
  height: 30vh;

}
.ContactInformationContainer{
  padding: 5% 0px;
}
.RequirementsHead{
  font-family: var(--Alegreya);
  text-align: center;
  margin-bottom: 3%;
}
.blackBox{
  background-color: #000000;
  padding: 30px;
  border-radius: 25px;
}
.footerContentHead{
  color: white;
  font-family: var(--Alegreya);
  font-size: clamp(1rem , 1.3vw , 2vw);
  padding-bottom: 3%;
}
.grayText{
  color: #a8a8a8;
}
.FooterImgMainParent{
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerImgParent{
  position: relative;
  background-image: url(../../../public/img/home/HomeContent/footerImg.webp);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footerContentUl{
  color: white;
  font-family: var(--Alegreya);
}
.footerContentUl li{
  font-size: clamp(1rem , 1.3vw , 2vw);
  padding: 13px;
}
@media screen and (max-width: 600px) {
  .HomeContaier{
    height: 130vh;
  }
  .gridChild {
    border: .2px solid rgba(0, 0, 0, 0.301);
    padding: 8px;
    text-align: center;
}
  .gridBox p{
   font-size: 3vw;
  }
  .whiteBox{
    margin: 10% 0px;
    width: 100%;
  }
}
@media only screen and (max-width: 990px) {
  #homepage-banner {
    display: none;
  }
  .CaseReportContainerLeft{
    display: none;
  }
}
.banner_top {
  overflow: hidden;
  background-image: url("../../assets/home.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 50vw;
}
@media only screen and (min-width: 990px) {
  #banner-mobile {
    display: none;
  }
}
.para-txt {
  text-align: justify;
}
.banner_bottom {
  padding: 1% 0px;
  width: 100%;
}
.slider_img {
  width: 25.57vw;
  height: 38vh;
  border-radius: 10px;
  object-fit: cover;
}
.slider_img2 {
  width: 53.3vw;
  height: 37vh;
  border-radius: 10px;
  object-fit: cover;
}
.txt-slider {
  width: 26vw;
}
.txt-slider-content {
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 43.55px;
  text-align: center;
  color: #fff;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}
#banner-mobile {
  min-height: 85vh;
  width: 100vw;
  background-image: url("../../assets/home.png");
}
.mobile-slider {
  width: 100%;
  padding-top: 1rem;
}
