@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.accommodation-banner {
  background-image: url(../../../public/img/Accommodaiton/banner.webp);
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}
.accommodation_head {
  color: #ffffff;
  font-size: 3vw;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 2px 10px black;
}
.accommodation_para {
  font-size: 1.5vw;
  color: white;
  text-align: center;
  font-weight: 600;
  text-shadow: 1px 2px 10px black;
}
.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.699);
  border-radius: 10px;
}
.hotels_img {
  width: 100%;
}
.card-body {
  flex: 1 1 auto;
  padding: 1rem 0.6rem;
}
.card-title {
  font-size: 1.5vw;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
}
.card-text {
  color: gray;
  font-weight: 600;
  font-size: 1vw;
}

.hotel_distance {
  padding: 0px;
  margin: 0;
}
.hotel_distance {
  padding: 5px 15px;
  border-radius: 10px;
  background-color: #ebeeee;
}
.callBtn {
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #dedede;
  color: black !important;
}
.callBtn_link {
  color: black !important;
  text-decoration: none;
}
.view_link {
  text-decoration: none;
}
.viewBtn {
  background-color: #61ba02;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px;
}
.map_icon {
  width: 1vw;
  height: 1vw;
}

@media only screen and (max-width: 1024px) {
  .callBtn {
    font-size: 1.2vw;
    padding: 5px;
  }
  .viewBtn {
    font-size: 1.3vw;
    padding: 5.5px;
  }
}
@media only screen and (max-width: 990px) {
  .accommodation_head {
    font-size: 4vw;
    margin-top: 15vw;
  }
  .accommodation_para {
    font-size: 2vw;
  }

  .inter_head {
    font-size: 4vw;
  }

  .inter_parra {
    font-size: 3vw;
  }

  .organized_head {
    font-size: 4.7vw;
  }

  .chief_head {
    font-size: 4vw;
  }

  .patron_head {
    font-size: 3.5vw;
  }

  .patron_parra {
    font-size: 3vw;
  }
  .callBtn {
    font-size: 1.9vw !important;
    padding: 5px;
  }
  .viewBtn {
    font-size: 1.9vw !important;
    padding: 10px !important;
  }
}
@media only screen and (max-width: 600px) {
  .accommodation_head {
    font-size: 6.5vw;
    margin-top: 23vw;
  }
  .accommodation_para {
    font-size: 3.5vw;
  }
  .card-title {
    font-size: 5vw;
  }
  .card-text {
    font-size: 3.2vw;
  }
  .callBtn {
    font-size: 3.5vw !important;
    padding: 10px;
  }
  .viewBtn {
    font-size: 3.9vw !important;
    padding: 10px !important;
  }
  .map {
    width: 60vw;
    height: 30vw;
  }

}
