.registrationbutton {
  text-decoration: none;
  border: 1px solid green;
  padding: .6rem 1.8rem;
  margin-top: 1rem ;
  color: white;
  border-radius: .2rem;
  background-color: #147745;
  
}

